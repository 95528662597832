<div class="d-flex flex-column gap-3 mt-2">
  <h3>Dashboard</h3>
  <div class="row mb-2">
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">Balance</div>
              <div
                class="d-flex align-self-center"
                placement="top left"
                ngbPopover="Your current balance"
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{ balance.balance | currency : "IDR" : true : "1.0" }}</b>
            </div>
          </div>
          <div
            class="bg-footer-card p-3 d-flex flex-row justify-content-center align-items-center gap-4"
          >
            <button
              class="btn p-0 text-white d-flex align-items-center gap-2"
              (click)="handleTopUp()"
            >
              <i class="icon icon-send-square"></i>
              <div class="d-flex text-card-button">Top Up</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList !== null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleTarikSaldo($event, 'balance')"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList === null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoRek($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList !== null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList === null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <a [routerLink]="['/finance/balance/detail']">
              <button
                class="d-flex text-white flex-row gap-2 align-items-center justify-content-center btn p-0"
              >
                <div><i class="icon icon-document-text"></i></div>
                <div class="d-flex text-card-button">Detail</div>
              </button>
            </a>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">
                Potensial Income
              </div>
              <div
                class="d-flex align-self-center"
                placement="top right"
                ngbPopover="Potential Income is the balance you will receive when the order status has changed from sent to received."
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{
                possibleIncome.possible_income | currency : "IDR" : true : "1.0"
              }}</b>
            </div>
          </div>
          <div class="footer-card-text p-3 d-flex flex-column">
            <div>
              Of this value, <b class="text-primary">Rp 0,-</b> needs to be
              monitored
            </div>
            <div></div>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">Commission</div>
              <div
                class="d-flex align-self-center"
                placement="top right"
                ngbPopover="Commission is calculated at 1% of total shipping costs"
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{ balance.commission | currency : "IDR" : true : "1.0" }}</b>
            </div>
          </div>
          <div
            class="bg-footer-card p-3 d-flex flex-row justify-content-center"
          >
            <button
              *ngIf="pinStatus === true && bankList !== null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleTarikKomisi($event, 'commission')"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList === null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoRek($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList !== null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList === null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <a [routerLink]="['/finance/commission/detail']">
              <button
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
              >
                <div><i class="icon icon-document-text"></i></div>
                <div class="d-flex text-card-button">Detail</div>
              </button>
            </a>
          </div>
        </nz-skeleton>
      </div>
    </div>
  </div>
  <div class="card shadow">
    <nz-skeleton
      [ngClass]="loading ? 'p-3' : 'p-0'"
      [nzLoading]="loading"
      [nzActive]="true"
    >
      <div class="d-flex flex-column">
        <div
          class="d-flex flex-row justify-content-between align-items-center p-3"
        >
          <div class="header">Order Dashboard</div>
          <div>
            <form class="row row-cols-sm-auto">
              <div class="col-12">
                <div
                  class="form-control d-flex flex-row"
                  (click)="openDatepicker()"
                >
                  <input
                    type="text"
                    class="btn p-0 w-100"
                    ngxDaterangepickerMd
                    [(ngModel)]="selected"
                    [ngModelOptions]="{ standalone: true }"
                    [showCustomRangeLabel]="false"
                    [keepCalendarOpeningWithRange]="true"
                    [alwaysShowCalendars]="true"
                    [ranges]="ranges"
                    [linkedCalendars]="true"
                    [isInvalidDate]="isInvalidDate"
                    [showCancel]="true"
                    opens="left"
                    drops="down"
                    [locale]="setlocale"
                    (change)="onChangeDate($event)"
                    style="border: none; font-size: 14px"
                    #datePickerInput
                  />
                  <button
                    class="btn ngx-daterangepicker-action"
                    (click)="openDatepicker()"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="divider"></div>
        <div class="d-flex p-2 card-ov gap-2">
          <div class="col-sm-6 col-lg-3 col-xl-3 col-8">
            <div class="total-shipment-card p-3 card-list-content">
              <div class="d-flex flex-column align-items-start gap-2 p-3">
                <div class="label">Total Shipment</div>
                <div class="mt-1 text-white txt">Total Shipment</div>
                <div class="text-white text-nominal">
                  <b>{{ accumulationOrder?.total_order || 0 }}</b>
                </div>
                <div class="mt-1 text-white txt-nom">Total COD Amount</div>
                <div class="text-white text-nominal">
                  <b>{{
                    (accumulationOrder?.total_cod
                      | currency : "IDR" : true : "1.0") || 0
                  }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 col-xl-3 col-8">
            <div class="delivered-card p-3 card-list-content">
              <div class="d-flex flex-column align-items-start gap-2 p-3">
                <div class="label">Delivered</div>
                <div class="text-white percent-deliver">
                  {{ accumulationOrder?.delivered.percentage | number }}%
                </div>
                <div class="mt-1 text-white txt">Delivered Package</div>
                <div class="text-white text-nominal">
                  <b>{{ accumulationOrder?.delivered.total_order || 0 }}</b>
                </div>
                <div class="mt-1 text-white txt-nom">Total COD Amount</div>
                <div class="text-white text-nominal">
                  <b>{{
                    (accumulationOrder?.delivered.total_cod
                      | currency : "IDR" : true : "1.0") || 0
                  }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 col-xl-3 col-8">
            <div class="returned-card p-3 card-list-content">
              <div class="d-flex flex-column align-items-start gap-2 p-3">
                <div class="label">Returned</div>
                <div class="text-white percent-deliver">
                  {{ accumulationOrder?.returned.percentage | number }}%
                </div>
                <div class="mt-1 text-white txt">Returned Package</div>
                <div class="text-white text-nominal">
                  <b>{{ accumulationOrder?.returned.total_order || 0 }}</b>
                </div>
                <div class="mt-1 text-white txt-nom">Total COD Amount</div>
                <div class="text-white text-nominal">
                  <b>{{
                    (accumulationOrder?.returned.total_cod
                      | currency : "IDR" : true : "1.0") || 0
                  }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 col-xl-3 col-8">
            <div class="process-returned-card p-3 card-list-content">
              <div class="d-flex flex-column align-items-start gap-2 p-3">
                <div class="label">On Process Return</div>
                <div class="text-white percent-deliver">
                  {{ accumulationOrder?.return_process.percentage | number }}%
                </div>
                <div class="mt-1 text-white txt">On Process Return Package</div>
                <div class="text-white text-nominal">
                  <b>{{
                    accumulationOrder?.return_process.total_order || 0
                  }}</b>
                </div>
                <div class="mt-1 text-white txt-nom">Total COD Amount</div>
                <div class="text-white text-nominal">
                  <b>{{
                    accumulationOrder?.return_process.total_cod
                      | currency : "IDR" : true : "1.0"
                  }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 col-xl-3 col-8">
            <div class="process-delivery-card p-3 card-list-content">
              <div class="d-flex flex-column align-items-start gap-2 p-3">
                <div class="label">On Process Delivery</div>
                <div class="text-white percent-deliver">
                  {{ accumulationOrder?.delivery.percentage | number }}%
                </div>
                <div class="mt-1 text-white txt">
                  On Process Delivery Package
                </div>
                <div class="text-white text-nominal">
                  <b>{{ accumulationOrder?.delivery.total_order || 0 }}</b>
                </div>
                <div class="mt-1 text-white txt-nom">Total COD Amount</div>
                <div class="text-white text-nominal">
                  <b>{{
                    (accumulationOrder?.delivery.total_cod
                      | currency : "IDR" : true : "1.0") || 0
                  }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-skeleton>
  </div>
</div>
