import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Define configurations for each mode
const environmentConfigs = {
  development: {
    mode: 'development',
    apiUrl: 'https://api.collaborator.komerce.my.id',
    fcm_token: 'fMFJVmT_vCk:APA91bEwLh3_5dGKDFJI9M4ISZrqD9sOuN_mtYKxj85ZyaLaxo1sBIi9Iq8X5ZUieX5GyGDfkl_woikKVfJCp5j-U7MxNWDE5GlNg8E3IpnsP7GQARlsbcMsLmiM9eMD5xXYTSGXUUKS',
    durian_pay: {
        base_url: 'https://api.durianpay.id/v1',
        api_key: 'dp_test_Z5XpH9fr1NSzG9Re',
    },
    RECAPTCHA_TOKEN: '6LfSUBIqAAAAALOqm4XgiiaxKYyP_3Svs2tlmePE',
  },
  sandbox: {
    mode: 'sandbox',
    apiUrl: 'https://api-sandbox.collaborator.komerce.id',
    fcm_token: 'fMFJVmT_vCk:APA91bEwLh3_5dGKDFJI9M4ISZrqD9sOuN_mtYKxj85ZyaLaxo1sBIi9Iq8X5ZUieX5GyGDfkl_woikKVfJCp5j-U7MxNWDE5GlNg8E3IpnsP7GQARlsbcMsLmiM9eMD5xXYTSGXUUKS',
    durian_pay: {
        base_url: 'https://api.durianpay.id/v1',
        api_key: 'dp_test_Z5XpH9fr1NSzG9Re',
    },
    RECAPTCHA_TOKEN: '6LdjmiUqAAAAAMj6nVqxh57XIj3i_pt0p5yx6aAR',
  },
  production: {
    mode: 'production',
    apiUrl: 'https://api.collaborator.komerce.id',
    fcm_token: 'fMFJVmT_vCk:APA91bEwLh3_5dGKDFJI9M4ISZrqD9sOuN_mtYKxj85ZyaLaxo1sBIi9Iq8X5ZUieX5GyGDfkl_woikKVfJCp5j-U7MxNWDE5GlNg8E3IpnsP7GQARlsbcMsLmiM9eMD5xXYTSGXUUKS',
    durian_pay: {
        base_url: 'https://api.durianpay.id/v1',
        api_key: 'dp_test_Z5XpH9fr1NSzG9Re',
    },
    RECAPTCHA_TOKEN: '6LdjmiUqAAAAAMj6nVqxh57XIj3i_pt0p5yx6aAR',
  }
};

@Injectable({
  providedIn: 'root'
})
export class ModeSwitchService {
  // Create a BehaviorSubject to hold the current mode (starts in development mode)
  private modeSubject = new BehaviorSubject(environmentConfigs.development);

  // Expose the BehaviorSubject as an observable
  mode$: Observable<{ mode: string; apiUrl: string, RECAPTCHA_TOKEN: string, fcm_token: string, durian_pay: Object }> = this.modeSubject.asObservable();

  // Method to switch between modes
  switchMode(newMode: 'development' | 'sandbox') {
    if (newMode in environmentConfigs) {
      this.modeSubject.next(environmentConfigs[newMode]);
    }
  }

  // Optional: get the current mode value as a snapshot (synchronous access)
  getCurrentMode() {
    return this.modeSubject.getValue().apiUrl;
  }
}
