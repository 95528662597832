<ngb-toast
*ngFor="let toast of toastService.toasts"
[class]="toast.classname"
style="z-index: 9999999 !important;"
[autohide]="true"
[delay]="toast.delay || 5000"
(hidden)="toastService.remove(toast)"
>
<ng-template ngbToastHeader>
  <div class="me-auto d-flex" *ngIf="toast.classname === 'bg-success'">
    <button class="check-success d-flex align-items-center justify-content-center" disabled>
      <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/Checklist.svg" alt="">
    </button>
    <strong class="mx-1" style="color:#34a770;">Success</strong>
  </div>
  <div class="me-auto d-flex" *ngIf="toast.classname === 'bg-danger'">
    <button class="check-danger d-flex align-items-center justify-content-center" disabled>
      <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/close.svg" alt="">
    </button>
    <strong class="mx-1" style="color: #ca3300">Error</strong>
  </div>
</ng-template>
 <div class="d-flex flex-row gap-3 align-items-center">
   <div *ngIf="toast.type === 'error'" style="color: white">
     <svg data-v-a5524346="" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle data-v-a5524346="" cx="12" cy="12" r="10"></circle><line data-v-a5524346="" x1="12" y1="8" x2="12" y2="12"></line><line data-v-a5524346="" x1="12" y1="16" x2="12.01" y2="16"></line></svg>
   </div>
  <div class="d-flex flex-column gap-1">
    <strong *ngIf="toast?.statusCode" class="">{{ toast?.statusCode }}</strong>
    <div> {{ toast.textOrTpl }}</div>
  </div>
 </div>
</ngb-toast>
