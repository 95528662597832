import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-existing-email',
  templateUrl: './modal-existing-email.component.html',
  styleUrls: ['./modal-existing-email.component.scss'],
})
export class ModalExistingEmailComponent implements OnInit {
  constructor(private modal: NzModalRef, private router: Router) {}

  destroyModal(): void {
    this.modal.destroy();
  }
  login() {
    this.router.navigate(['/dashboard']);
    this.modal.destroy();
  }
  ngOnInit(): void {}
}
