<form [action]="">
    <div class="d-flex flex-column gap-0 justify-content-center align-items-center">
      <div class="d-flex justify-content-end w-100 p-1">
        <img (click)="handleClose($event)" style="cursor: pointer;" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="">
      </div>
      <div class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center">
        <div class="title text-center">Enter PIN</div>
        <div class="text-center">Enter your Pin to continue</div>
  
        <div class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1">
          <otp [setting]="settings" (keydown)="onKeyDown($event)" (onValueChange)="onChange($event)"></otp>
        </div>
        <div *ngIf="isErrorMessage === true" class="p-2 my-1" style="background-color: #FFECE9;border-radius: 8px;">
          <small class="text-danger">The PIN you entered is incorrect</small>
        </div>
        <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
            <button class="btn btn-outline-primary w-50" (click)="handleClose($event)">Cancel</button>
          <button [disabled]="isDisabled" (click)="handleConfirm($event)"
            [class]="isDisabled ? 'btn btn-secondary w-50' : 'btn btn-primary w-50 text-white'">
            <div class="ps-3 pe-3">Confirm</div>
          </button>
        </div>
      </div>
    </div>
  </form>