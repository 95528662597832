import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbTypeahead,
} from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-modal-buat-rek',
  templateUrl: './buat-rek.component.html',
  styleUrls: ['./buat-rek.component.scss'],
})
export class BuatRekeningComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, private route: Router) {}
  ngOnInit(): void {}

  handleClose(e: any): void {
    this.activeModal.dismiss();
  }

  handleConfirm(e: any): void {
    this.route.navigate(['/bank-account']);
    this.activeModal.dismiss();
  }
}
