<div class="mt-4 d-flex align-items-center justify-content-center">
  <h4>Create Ticket</h4>
</div>
<form
[formGroup]="formGroup"
  action=""
  class="offcanvas-body d-flex flex-column"
  style="align-content: space-between"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="mt-2 px-4">
      <label for="" class="text-bold">Receipt Number</label>
      <input
        id="typeahead-template"
        [(ngModel)]="awb"
        type="search"
        class="form-control"
        [ngbTypeahead]="search"
        #inputValue
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        selectOnExact
        placeholder="Type or Paste Receipt"
        required
        formControlName="awbb"
        (ngModelChange)="onSearchAwb($event)"
        (keydown.enter)="$event.preventDefault()"
      />
      <small *ngIf="isNotFound" class="text-danger">Receipt Number Not Found or Ticket Already Created</small>
  </div>
  <div class="mt-4 px-4">
      <label for="" class="text-bold">Customer</label>
      <input
        [(ngModel)]="customer"
        placeholder="Enter customer name"
        class="form-control rounded"
        type="text"
        name="customer"
        formControlName="customer"
        readonly
      />
  </div>
  <div class="mt-4 px-4">
    <label for="" class="text-bold text-black">Ticket Type</label>
    <select class="form-select" aria-label="Default select example" formControlName="type_ticket"  placeholder="Select Ticket Type">
      <option selected disabled>Select Ticket Type</option>
      <option [value]="listTypeTicket[i].id" *ngFor="let items of listTypeTicket; let i = index">
        {{items.name}}
      </option>
    </select>
    
  </div>
  <div class="mt-4 px-4">
      <label for="" class="text-bold">Description</label>
      <textarea
        nz-input
        class="rounded"
        [(ngModel)]="description"
        placeholder="Please write clearly the difference between the BEFORE and AFTER addresses. If there is additional shipping cost based on information from the expedition, it will be charged manually by reducing your balance."
        [nzAutosize]="{ minRows: 5, maxRows: 7 }"
        name="description"
        formControlName="description"
      ></textarea>
  </div>
  <div class="d-flex mt-4 px-4 align-items-center">
    <input type="file" name="file" (change)="handleFileUpload($event)" #fileupload class="form-control d-none" id="attachment" multiple>
    <button *ngIf="fileUpload.length === 0" class="btn d-flex align-items-center p-0" (click)="triggerFileInput()">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
        alt=""
        width="16px"
      />
      <span class="mx-2" style="color: #08a0f7; font-size: 12px;">Attach File</span>
    </button>
    <div [ngClass]="fileUpload.length > 1 ? 'd-flex flex-column' : ''">
      <div *ngFor="let file of fileUpload; let i = index" class="d-flex align-items-center" style="margin-bottom: 5px;">
        <img
        src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
        alt=""
        width="16px"
      />
        <span class="mx-2" style="color: #08a0f7; font-size: 12px;">{{ file.name }}</span>
        <button class="btn d-flex justify-content-center align-items-center" style="background-color: rgb(242, 242, 242); height: 18px; width: 14px;" (click)="deleteFile(i)">
          <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg" alt="" width="12px">
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end p-4 gap-2">
    <button class="btn btn-outline-primary rounded" (click)="cancel()">
      <span class="text-bold">Cancel</span>
    </button>
    <button class="btn rounded" (click)="createTicket()" [disabled]="formGroup.invalid"
    [ngClass]="formGroup.invalid ? 'btn-secondary' : 'btn-primary text-white'">
      <span class="text-bold">Create Ticket</span>
    </button>
  </div>
</form>
