<div class="text-center d-flex flex-column p-4">
    <div>
        <img src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png" alt="warning" width="100px">
        <div class="title mt-3">Your previous top-up isn’t complete yet</div>
    </div>
    <span class="mt-3 text-grey">
        Please complete the payment to continue
    </span>
    <div class="d-flex justify-content-betweeen mt-3 gap-2">
        <button class="btn btn-outline-primary col-6" (click)="handleCancel()">Cancel Payment</button>
        <button class="btn btn-primary text-white col-6" (click)="handleCheckStatus()">Pay Now</button>
    </div>
</div>