import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseService } from 'src/app/services/base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RECAPTCHA_TOKEN, VERIFY } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-activate-register',
  templateUrl: './activate-register.component.html',
  styleUrls: ['./activate-register.component.scss'],
})
export class ActivateRegisterComponent implements OnInit, OnDestroy {
  captchaToken: string = '';
  destroy$ = new Subject<void>();
  token: string = '';
  title: string = 'Verifying';

  constructor(
    private renderer: Renderer2,
    private message: NzMessageService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token') || '';

    this.loadRecaptchaScript().then(() => {
      this.getCaptchaToken();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  loadRecaptchaScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => resolve(); // Resolve the promise once the script is loaded
      script.onerror = () =>
        reject(new Error('Failed to load reCAPTCHA script'));
      this.renderer.appendChild(document.head, script);
    });
  }

  async getCaptchaToken(): Promise<void> {
    try {
      await grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'ACTIVATE' }
        );
        this.captchaToken = token;
        await this.handleActivate(token);
      });
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
      this.message.create('error', 'Error executing reCAPTCHA');
    }
  }

  async handleActivate(recaptcha: string): Promise<void> {
    const token = this.token;
    const recaptcha_token = recaptcha;

    try {
      const response = await this.baseService
        .postData(VERIFY, null, { token, recaptcha_token })
        .pipe(takeUntil(this.destroy$))
        .toPromise();
      this.router.navigate(['register-success']);
    } catch (err: any) {
      this.message.create('error', 'Verification Failed, Please Try Again');
      this.title = 'Verification Failed, Please Try Again';
      if (err.error.data.errors) this.title = 'This user has been active before';
    }
  }
}
