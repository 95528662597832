<nz-skeleton [ngClass]="loading ? 'p-3':'p-0'" [nzLoading]="loading" [nzActive]="true">
  <div
    class="d-flex justify-content-between gap-2 px-4"
    nz-row
    [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
  >
  <div class="d-flex gap-2 justify-content-start col-lg-4">
    <button nz-button nzType="primary" class="rounded" (click)="createTicket()">
      Create Ticket
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/add.svg"
        alt=""
      />
    </button>
    <button
      *ngIf="isAll === false"
      nz-button
      nzType="primary"
      nzDanger
      class="rounded"
      (click)="getUnread('unread')"
    >
    Unread Message ({{ unreadTicket }})
    </button>
    <button
      *ngIf="isAll === true"
      class="btn btn-outline-secondary"
      style="height: 32px"
      (click)="getUnread('all')"
    >
    All Message
    </button>
  </div>
  <div class="d-flex gap-2 justify-content-end gap-2 col-lg-4">
    <nz-input-group [nzPrefix]="suffixIconSearch" class="rounded">
      <input
        [(ngModel)]="searchFilter"
        type="text"
        nz-input
        placeholder="Search for ticket number, receipt, or customer name"
        (keydown.enter)="cariFilter()"
      />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
    <button
      nz-button
      nzType="primary"
      nzDanger
      class="rounded"
      (click)="cariFilter()"
    >
      Search
    </button>
  </div>
</div>
<hr />
<div class="d-flex gap-2 justify-content-end gap-2 col-lg-12 flex-wrap">
  <button
    class="rounded"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="shipping"
    nzTrigger="click"
    [nzClickHide]="false"
  >
    Expedition
    <img
      class="ms-2"
      src="https://storage.googleapis.com/komerce/assets/icons/sort-icon-new.svg"
      alt=""
    />
  </button>
  <nz-dropdown-menu #shipping="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let list of ekspedisi; let i = index">
        <input
          class="form-check-input"
          type="checkbox"
          [value]="list.value"
          [id]="list.id"
          [checked]="list.checked"
          (change)="selectEkspedisi($event, list)"
        />
        <label class="form-check-label mx-2" [for]="list.id">
          {{ list.label }}
        </label>
      </li>
    </ul>
  </nz-dropdown-menu>
  <button
    class="rounded"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="type"
    nzTrigger="click"
    [nzClickHide]="false"
  >
    Ticket Type
    <img
      class="ms-2"
      src="https://storage.googleapis.com/komerce/assets/icons/sort-icon-new.svg"
      alt=""
    />
  </button>
  <nz-dropdown-menu #type="nzDropdownMenu">
    <ul nz-menu style="max-height: 200px; overflow-y: auto">
      <li nz-menu-item *ngFor="let list of listTypeTicket; let i = index">
        <input
          class="form-check-input"
          type="checkbox"
          [value]="list.name"
          [id]="list.id"
          [checked]="list.checked"
          (change)="selectTypeTicket($event, list)"
        />
        <label class="form-check-label mx-2" [for]="list.id">
          {{ ListTypeTicket(list.name) }}
        </label>
      </li>
    </ul>
  </nz-dropdown-menu>
  <button
    class="rounded"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="status"
    nzTrigger="click"
    [nzClickHide]="false"
  >
    Ticket Status&nbsp;<span class="text-danger"
      >({{ selectedStatusTicket.length }})</span
    >
    <img
      class="ms-2"
      src="https://storage.googleapis.com/komerce/assets/icons/sort-icon-new.svg"
      alt=""
    />
  </button>
  <nz-dropdown-menu #status="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let list of statusTicket; let i = index">
        <input
          class="form-check-input"
          type="checkbox"
          [value]="list.value"
          [id]="list.id"
          [checked]="list.checked"
          (change)="selectStatusTicket($event, list)"
        />
        <label class="form-check-label mx-2" [for]="list.id">
          {{ list.label }}
        </label>
      </li>
    </ul>
  </nz-dropdown-menu>
  <button
    class="btn border rounded ngx-daterangepicker-action"
    style="height: 32px; font-size: 14px"
    (click)="openDatepicker($event)"
  >
    Date Created
    <img
      class="ms-2"
      src="https://storage.googleapis.com/komerce/assets/icons/sort-icon-new.svg"
      alt=""
    />
  </button>
  <input
    aria-labelledby="datepicker"
    type="text"
    class="d-none"
    ngxDaterangepickerMd
    [(ngModel)]="selected"
    [showCustomRangeLabel]="false"
    [alwaysShowCalendars]="true"
    [ranges]="ranges"
    [linkedCalendars]="true"
    [isInvalidDate]="isInvalidDate"
    [showCancel]="true"
    [locale]="setlocale"
    opens="left"
    drops="down"
    (change)="onChangeDate($event)"
    style="font-size: 12px"
    readonly
    #datepicker
  />
  <button
    nz-button
    nzType="default"
    nzDanger
    class="rounded"
    (click)="deleteFilter()"
  >
    <span class="text-bold">Delete</span>
  </button>
  <hr />
</div>
</nz-skeleton>
<nz-skeleton [ngClass]="loading ? 'p-3':'p-0'" [nzLoading]="loading" [nzActive]="true"></nz-skeleton>
<nz-table
*ngIf="!loading"
  #sortTable
  [nzScroll]="{ x: '1200px', y: '500px' }"
  [nzData]="listOfData"
  [nzBordered]="false"
  [nzNoResult]="emptystate"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  class="mt-4"
  nzSize="middle"
>
  <thead>
    <tr>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
      >
        {{ column.title }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-template nz-virtual-scroll let-data let-index="index">
      <tr
        (click)="detail(data.no_ticket)"
        style="cursor: pointer"
        [ngClass]="data.read === 0 ? 'bg-unread' : ''"
      >
        <td style="color: #f95031">{{ data.no_ticket }}</td>
        <td class="text-bold">{{ data.awb }}</td>
        <td>
          <img [src]="data.shipper" alt="shipper" height="40px" />
        </td>
        <td class="text-bold">{{ data.customer }}</td>
        <td class="text-bold">{{ ListTypeTicket(data.type_ticket) }}</td>
        <td class="text-bold">{{ data.delay_action }}</td>
        <td class="text-bold" [ngClass]="classStatus(data.status_ticket)">
          {{ statusTicketName(data.status_ticket) }} <br />
          <span
            *ngIf="data.status_ticket !== 'Dibatalkan'"
            [ngClass]="classStatusOrder(data.status_order)"
            >{{ statusOrderValue(data.status_order) }}</span
          >
        </td>
        <td class="text-bold">
          {{ data.created_at | date : "dd MMMM yyyy" : "en-US" }} <br />
          <small>{{ data.created_at | date : "HH:mm" }} WIB</small>
        </td>        
        <td class="text-bold">
          {{ data.updated_at | date : "dd MMMM yyyy" : "en-US" }} <br />
          <small>{{ data.updated_at | date : "HH:mm" }} WIB</small>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>
<div *ngIf="!loading"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <div class="flex-row d-flex align-items-center">
    <div>List per page:</div>
    <div class="btn-group ms-2" role="group" aria-label="First group">
      <div class="d-flex">
        <div
          [ngClass]="
            filterTicketing.per_page === '250' ||
            filterTicketing.per_page === 250
              ? 'btn-primary'
              : ''
          "
          class="d-flex gap-3 btn-pg ms-1 radio-container"
        >
          <input
            type="radio"
            [name]="'radio'"
            [id]="'radio'"
            [value]="250"
            class="btn btn-prim"
            (change)="getListPage($event)"
          />
          <label for="">250</label>
        </div>
        <div
          [ngClass]="filterTicketing.per_page === '500' ? 'btn-primary' : ''"
          class="d-flex gap-3 btn-pg ms-1 radio-container"
        >
          <input
            type="radio"
            [name]="'radio'"
            [id]="'radio'"
            [value]="500"
            class="btn-pg btn-prim"
            (change)="getListPage($event)"
          />
          <label for="">500</label>
        </div>
      </div>
    </div>
  </div>
  <div
    class="btn-group mt-3 mt-md-0 d-flex align-items-center"
    role="group"
    aria-label="First group"
  >
    <button
      [disabled]="isDisPrev"
      type="button"
      class="btn-pg"
      (click)="prevPage()"
    >
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
        alt=""
        width="20px"
      />
    </button>
    <button
      *ngIf="filterTicketing.page > 1"
      class="btn number-pn d-flex align-items-center justify-content-center"
      (click)="prevPage()"
    >
      {{ filterTicketing.page - 1 }}
    </button>
    <button class="btn number-page">
      {{ filterTicketing.page }}
    </button>
    <button
      [disabled]="isDisNext"
      *ngIf="totalTicket / totalPage < filterTicketing.per_page"
      class="btn number-pn d-flex align-items-center justify-content-center"
      [ngClass]="filterTicketing.page + 1 > totalPage ? 'd-none' : ''"
      (click)="nextPage()"
    >
      {{ +filterTicketing.page + 1 }}
    </button>
    <button
      [disabled]="isDisNext"
      *ngIf="totalPage > 2"
      class="btn number-pn d-flex align-items-center justify-content-center"
      [ngClass]="filterTicketing.page !== 1 ? 'd-none' : ''"
      (click)="nextThree()"
    >
      3
    </button>
    <button
      [disabled]="isDisNext"
      type="button"
      class="btn-pg"
      (click)="nextPage()"
    >
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg"
        alt=""
        width="20px"
      />
    </button>
  </div>
</div>
<ng-template #emptystate>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentTpl">
    <ng-template #contentTpl>
      <span>No Data</span>
    </ng-template>
  </nz-empty>
</ng-template>
