import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbActiveModal,
  NgbDropdownModule,
  NgbModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthLoginComponent } from './pages/auth-login/auth-login.component';
import { KeuanganSaldoComponent } from './pages/keuangan/saldo/keuangan-saldo.component';
import { TableRiwayatPenarikanSaldoComponent } from './pages/keuangan/components/table-riwayat-penarikan-saldo/table-riwayat-penarikan-saldo.component';
import { DataOrderComponent } from './pages/data-order/data-order.component';
import { LibsModule } from './libs/libs.module';
import { BaseService } from './services/base.service';
import { UserService } from './services/api/user.service';
import { BankService } from './services/api/bank.service';
import { OrderService } from './services/api/order.service';
import { BalanceService } from './services/api/balance.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AuthInterceptor,
  authInterceptorProviders,
} from './interceptors/http.interceptor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { TableDataOrderComponent } from './pages/data-order/components/table-data-order/table-data-order.component';
import { SharedModuleDataOrder } from './pages/data-order/components/table-data-order/shared/shared.module';
import { DetailDataOrderComponent } from './pages/data-order/detail-data-order/detail-data-order.component';
import { DetailRiwayatSaldoComponent } from './pages/keuangan/components/detail-riwayat-penarikan-saldo/detail-riwayat-penarikan-saldo.component';
import { ToastService } from './services/toast.service';
import { SharedModule } from './modules/shared/shared.module';
import { LoaderComponent } from './modules/shared/components/loader/loader.component';
import { ModalPinComponent } from './pages/pengaturan/pages/profile/components/modal-pin-verification/modal-pin-verification.component';
import { OtpPipe } from './pipes/otp.pipe';
import { RekeningComponent } from './pages/pengaturan/pages/rekening/rekening.component';
import { DetailSaldoComponent } from './pages/keuangan/components/detail-saldo/detail-saldo.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { KeuanganKomisiComponent } from './pages/keuangan/komisi/keuangan-komisi.component';
import { DetailKomisiComponent } from './pages/keuangan/components/detail-komisi/detail-komisi.component';
import { ModalDlRincianSaldo } from './modules/shared/components/modal-download-rincian-saldo/modal-download-rincian-saldo.component';
import { AntDModule } from './modules/shared/antd.module';
import { ModalDlDataOrder } from './modules/shared/components/modal-download-data-order/modal-download-data-order.component';
import { TicketingComponent } from './pages/ticketing/ticketing.component';
import { TicketingModule } from './pages/ticketing/ticketing.module';
import { TableTicketingComponent } from './pages/ticketing/components/table-ticket/table-ticket.component';
import { ModalCreateTicketComponent } from './pages/ticketing/components/modal-create-ticket/modal-create-ticket.component';
import { DetailTicketComponent } from './pages/ticketing/components/detail-ticket/detail-ticket.component';
import { MessageTicketComponent } from './pages/ticketing/components/message-ticket/message-ticket.component';
import { TicketService } from './services/api/ticket.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);
import { provideNzI18n, en_US } from 'ng-zorro-antd/i18n';
import { WebSocketService } from './services/web-socket.service';
import { RegisterComponent } from './pages/register/register.component';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { RegisterSuccessComponent } from './pages/register/register-success/register-success.component';
import { ModalRegisterSuccessComponent } from './pages/register/modal-register-success/modal-register-success.component';
import { ActivateRegisterComponent } from './pages/register/activate-register/activate-register.component';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ProfileService } from './services/profile.service';
import { AuthService } from './services/auth.service';
import { BillingsComponent } from './pages/billings/billings.component';
import { ModalExistingEmailComponent } from './pages/register/modal-alert-existing/modal-existing-email.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { QRCodeModule } from 'angularx-qrcode';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { ModeSwitchService } from './services/mode-switch.service';

const ngZorroConfig: NzConfig = {
  theme: {
    primaryColor: '#f3c130',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AuthLoginComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    ActivateRegisterComponent,
    ModalRegisterSuccessComponent,
    KeuanganSaldoComponent,
    KeuanganKomisiComponent,
    TableRiwayatPenarikanSaldoComponent,
    DataOrderComponent,
    TableDataOrderComponent,
    DetailDataOrderComponent,
    DetailRiwayatSaldoComponent,
    DetailSaldoComponent,
    DetailKomisiComponent,
    LoaderComponent,
    ModalPinComponent,
    RekeningComponent,
    ModalDlRincianSaldo,
    ModalDlDataOrder,
    TicketingComponent,
    TableTicketingComponent,
    ModalCreateTicketComponent,
    DetailTicketComponent,
    MessageTicketComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    BillingsComponent,
    ModalExistingEmailComponent,
    PageNotFoundComponent,
  ],
  providers: [
    BaseService,
    UserService,
    BankService,
    OrderService,
    ToastService,
    BalanceService,
    TicketService,
    OtpPipe,
    WebSocketService,
    ProfileService,
    AuthService,
    ModeSwitchService,
    authInterceptorProviders,
    provideNzI18n(en_US),
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    LibsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgbDropdownModule,
    DashboardModule,
    SharedModuleDataOrder,
    SharedModule,
    NgbTypeaheadModule,
    AntDModule,
    TicketingModule,
    BrowserAnimationsModule,
    AuthLayoutModule,
    ScrollingModule,
    QRCodeModule,
  ],
})
export class AppModule {}
