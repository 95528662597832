<div class="not-found-container">
  <img
    src="https://storage.googleapis.com/komerce/rajaongkir/bg-illust-ro.svg"
    class="background"
  />
  <div class="text-center mt-5 content-not-found">
    <img
      src="https://storage.googleapis.com/komerce/assets/svg/404.svg"
      alt="not_found"
      width="500px"
      class="mb-4"
    />
    <h1>Page Not Found</h1>
    <p>Sorry, the page you're looking for doesn't exist.</p>
    <a routerLink="/dashboard">
      <button class="btn btn-primary text-white">Go back to Home</button>
    </a>
  </div>
</div>
