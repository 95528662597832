import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/services/base.service';
import { Subject, takeUntil } from 'rxjs';
import {
  CANCEL_INVOICE,
  CHECK_PAYMENT,
} from 'src/app/constants/endpoint.const';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-modal-qris-payment',
  templateUrl: './modal-qris-payment.component.html',
  styleUrls: ['./modal-qris-payment.component.scss'],
})
export class ModalQrisPaymentComponent implements OnInit {
  destroy$ = new Subject();
  @Input() allowQris: boolean = true;
  @Input() invoiceId: string = '';
  @Input() expiredAt: string = '';
  @Input() qrCode: string = '';
  @Input() packageId: number = 0;
  @Input() totalPrice: number = 0;

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private BaseService: BaseService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  formatNumber(n: any) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  handlePaid(): void {
    const params = {
      invoice_id: this.invoiceId,
    };
    this.BaseService.getData(CHECK_PAYMENT, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.data.status === 'unpaid') {
            this.message.create(
              'error',
              'You still have an active bill, please complete the payment',
              {
                nzDuration: 5000,
              }
            );
          } else {
            this.activeModal.close();
            this.message.create(
              'success',
              `Top up complete! Rp ${
                formatNumber(Number(
                  this.totalPrice), 'en-US', '1.0-0')
              } has been added to your balance`
            );
            window.location.reload()
          } 
        },
        (err) => {
          this.message.create('error', err.data.errors);
        }
      );
  }

  cancelPayment() {
    const params = {
      invoice_id: this.invoiceId,
    };
    this.BaseService.putData(CANCEL_INVOICE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.activeModal.close();
          this.message.create('success', 'Your payment has been canceled');
        },
        (err) => {
          this.message.create('error', err.data.errors);
        }
      );
  }
}
