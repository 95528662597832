<div class="p-4" id="dlsaldo">
    <div class="mt-2">
        <h5>Download Balance Detail</h5>
        <span>Customize the balance details you want to download.</span>
    </div>
    <div class="mt-4">
        <span>Select Range</span>
        <form class="row row-cols-sm-auto w-90">
                <div class="form-control d-flex flex-row">
                  <input
                    type="text"
                    class="w-100"
                    ngxDaterangepickerMd
                    [(ngModel)]="selected"
                    [ngModelOptions]="{standalone: true}"
                    [showCustomRangeLabel]="true"
                    [keepCalendarOpeningWithRange]="true"
                    [alwaysShowCalendars]="true"
                    [ranges]="ranges"
                    [linkedCalendars]="true"
                    [isInvalidDate]="isInvalidDate"
                    [showCancel]="true"
                    opens="right"
                    drops="down"
                    [locale]="setlocale"
                    (change)="onChangeDate($event)"
                    style="border: none; font-size: 14px;"
                    readonly
                  />
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                    alt=""
                  />
                </div>
          </form>
          <hr>
          <div class="d-flex justify-content-between col-12">
            <div *ngIf="progress > 0" class="d-flex flex-column justify-content-start col-6">
              <span *ngIf="progress < 100 true">Loading File</span>
              <span *ngIf="progress === 100">File successfully downloaded</span>
              <nz-progress [nzPercent]="progress"></nz-progress>
            </div>
            <div class="d-flex gap-2 justify-content-end" [ngClass]="progress > 0 ? 'col-6':'col-12'">
              <button class="btn btn-outline-primary" style="height: 40px;" (click)="handleClose($event)">Cancel</button>
              <button class="d-flex btn btn-primary text-white" style="height: 40px;" [disabled]="downloaded" (click)="download()">
                <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>
                <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                {{txt_download}}
                </button>
            </div>
          </div>
    </div>
</div>