export const environment = {
  production: false,
  BASE_URL: 'https://api.collaborator.komerce.my.id',
  fcm_token:
    'fMFJVmT_vCk:APA91bEwLh3_5dGKDFJI9M4ISZrqD9sOuN_mtYKxj85ZyaLaxo1sBIi9Iq8X5ZUieX5GyGDfkl_woikKVfJCp5j-U7MxNWDE5GlNg8E3IpnsP7GQARlsbcMsLmiM9eMD5xXYTSGXUUKS',
  durian_pay: {
    base_url: 'https://api.durianpay.id/v1',
    api_key: 'dp_test_Z5XpH9fr1NSzG9Re',
  },
  RECAPTCHA_TOKEN: '6LfSUBIqAAAAALOqm4XgiiaxKYyP_3Svs2tlmePE',
  envName: 'dev',
};
