import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import { EMPTY, Subject, Subscription, catchError, interval } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { DOWNLOAD_BALANCE } from 'src/app/constants/endpoint.const';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'modal-dl-saldo',
  templateUrl: './modal-download-rincian-saldo.component.html',
  styleUrls: ['./modal-download-rincian-saldo.component.scss'],
})
export class ModalDlRincianSaldo implements OnInit {
  @Input() data: any;
  destroy$ = new Subject();
  @Input() filterList: any = {
    start_date: moment()
      .startOf('month')
      .locale('id')
      .format('YYYY-MM-DD 00:00:01'),
    end_date: moment()
      .endOf('month')
      .locale('id')
      .format('YYYY-MM-DD 23:59:59'),
  };

  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last 60 Days': [moment().subtract(59, 'days'), moment()],
    'All': [moment().startOf('year').subtract(1, 'year'), moment()],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = {
    applyLabel: 'Save',
    cancelLabel: 'Cancel',
    customRangeLabel: 'Custom tanggal',
    separator: ' - ',
    clearLabel: 'Cancel',
    format: 'DD MMMM YYYY',
    displayFormat: 'DD MMMM YYYY',
    daysOfWeek: ['Sun |', 'Mon |', 'Tue |', 'Wed |', 'Thu |', 'Fri |', 'Sat'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      ],
  };
  keepCalendarOpeningWithRange: boolean;
  downloading: boolean = false;
  downloaded: boolean = false;
  txt_download: string = 'Download';
  progress: number = 0;
  tokem: string = '';
  private intervalSubscription!: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private http: HttpClient
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {
  }

  handleClose(e: any): void {
    if (this.progress > 0 && this.progress < 100) {
      this.toastService.show(null, 'Download canceled', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
    }
    
    this.downloading = false
    this.downloaded = false
    this.progress = 0
    this.activeModal.dismiss();
    this.intervalSubscription.unsubscribe();
  }

  download(): void {
    this.progress = 0
    this.downloading = true;
    this.downloaded = true;
    this.txt_download = 'Downloading...'

    this.toastService.show(null, 'The file will be downloaded soon, please wait', 'success', {
      classname: 'bg-success',
      delay: 3000,
    });

    this.intervalSubscription = interval(50).subscribe(() => {
      if (this.progress < 100) {
        this.progress++;
      } else {
        let url = DOWNLOAD_BALANCE as any;
        const params = new URLSearchParams(this.filterList).toString();
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
        });
        this.http.get(url + '?' + params, { headers, responseType: 'blob' })
        .pipe(
          catchError(error => {
            console.error('Download failed:', error);
            this.toastService.show(null, 'Download failed', 'danger', {
              classname: 'bg-danger',
              delay:  3000,
            });
            this.progress = 0
            this.txt_download = 'Download';
            this.downloaded = false;
            return EMPTY;
          })
        )
        .subscribe((blob: Blob) => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = localStorage.getItem('auth-user')?.slice(1).replace(/\s+/g, '_') + 'Mutasi_' + moment().format('YYYY-MM-DD') + '.xlsx'
            document.body.appendChild(a);
            a.click();
            a.remove();
            this.downloaded = false
            this.txt_download = 'Download'

            this.toastService.show(null, 'Successfully downloaded the file', 'success', {
              classname: 'bg-success',
              delay: 5000,
            });
          });

        this.intervalSubscription.unsubscribe();
      }
    });
  }

  onChangeDate(e: any) {
    this.downloading = false;
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filterList.start_date = startDate.format('YYYY-MM-DD 00:00:01');
    this.filterList.end_date = endDate.format('YYYY-MM-DD 23:59:59');

    this.filterResult.emit(this.filterList);
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe()
  }
}
