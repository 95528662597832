<div class="d-flex justify-content-center flex-column text-center p-4">
  <div class="mb-3">
    <img
      src="https://storage.googleapis.com/komerce/assets/icons/Icon-Popup.svg"
      alt="warning"
    />
  </div>
  <h5><strong>Email Has Been Used</strong></h5>
  <span>Use another email or login with your account</span>
  <div class="d-flex justify-content-between gap-1">
    <button
      style="border-radius: 8px"
      class="mt-3 col-6"
      nz-button
      nzSize="large"
      nzType="default"
      style="color: #fabc05; border: 1px solid #fabc05; border-radius: 8px"
      (click)="destroyModal()"
    >
      Close
    </button>
    <button
      style="border-radius: 8px"
      class="mt-3 col-6"
      nz-button
      nzSize="large"
      nzType="primary"
      (click)="login()"
    >
      Login
    </button>
  </div>
</div>
