export const SHOW_PER_PAGE = [50, 100, 200]
export const SHOW_PER_PAGE_DASHBOARD = [20, 50]

export const PAGINATION = {
  CURRENT: "",
  FIRST: "",
  PREV: "",
  NEXT: "",
  LAST: "",
  PER_PAGE: 0,
  PAGE: 0,
  TOTAL: 0,
  PAGE_COUNT:0,

}
