<div class="d-flex justify-content-center flex-column text-center p-4">
    <h5><strong>Scan Qris</strong></h5>
    <span>Please scan Qris to make payment</span>
    <div class="shadow rounded mt-4">
      <div class="d-flex justify-content-between p-4">
        <div class="d-flex justify-content-start gap-2">
          <img
            src="https://storage.googleapis.com/komerce/assets/svg/Qris-logo.svg"
            alt="qris"
            width="80px"
          />
          <div class="d-flex flex-column">
            <span> QR Code Standart </span>
            <span> National Payment </span>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end justify-content-end">
          <span class="text-bold">Pay Before</span>
          <span class="text-danger">{{
            expiredAt | date : "dd MMMM yyyy" || "-"
          }}</span>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center px-4"
      >
        <span class="title">KOMERCE.ID</span>
      </div>
      <div
        class="px-4 mt-3 d-flex flex-column align-items-center justify-content-center"
      >
        <qrcode
          [qrdata]="qrCode"
          [width]="256"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <span class="title mt-2">{{ totalPrice | idr }}</span>
      </div>
      <div class="mt-4 bg-footer"></div>
    </div>
    <div class="col-12 mt-4 d-flex flex-column">
      <button class="btn text-grey" (click)="cancelPayment()">
        Cancel Payment
      </button>
      <button class="btn btn-primary text-white" (click)="handlePaid()">
        Already Paid
      </button>
    </div>
  </div>
  