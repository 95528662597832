<div class="py-2">
  <nz-card class="rounded p-4">
    <nz-skeleton [ngClass]="loading ? 'p-3':'p-0'" [nzLoading]="loading" [nzActive]="true">
      <div class="d-flex align-items-center">
        <Button
          style="border: none; background-color: transparent"
          (click)="back()"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
            alt=""
            width="40px"
          />
        </Button>
        <span
          class="text-black"
          style="font-size: 20px; font-weight: 500; margin-left: 15px"
          >Ticket Detail</span
        >
      </div>
      <div class="border rounded mt-4 p-4 align-items-center justify-content-center">
          <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-start">
                  <b>Ticket Detail</b>
              </div>
              <div class="d-flex justify-content-end">
                  <button class="btn" type="button"
                  (click)="collapse.toggle()"
                  [attr.aria-expanded]="!isCollapsed"
                  aria-controls="collapseExample">
                      <img
                      *ngIf="isCollapsed"
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                      alt=""
                      />
                      <img
                      *ngIf="!isCollapsed"
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
                      alt=""
                      />
                  </button>
                </div>
              </div>
              <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                  <div class="row mt-3">
                    <div class="col-6">
                      <div>
                        <label for="">Ticket Status</label>
                        <div class="rounded col-5 text-center" [ngClass]="classStatus(status_ticket)">{{statusTicketName(status_ticket)}}</div>
                      </div>
                      <div class="mt-3">
                        <label for="">Ticket Number</label>
                        <nz-input-group class="rounded" [nzSuffix]="ticketNumber">
                          <input
                            [(ngModel)]="ticket_number"
                            nz-input
                            placeholder="Ticket Number"
                            class="rounded"
                            type="text"
                            readonly
                          />
                        </nz-input-group>
                        <ng-template #ticketNumber>
                          <button class="btn p-0" (click)="salinTicket()">
                            <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg" alt="">
                          </button>
                        </ng-template>
                      </div>
                      <div class="mt-3">
                        <label for="">Expedition</label>
                        <input
                          nz-input
                          [(ngModel)]="shipping"
                          placeholder="Expedition"
                          class="rounded"
                          type="text"
                          readonly
                        />
                      </div>
                      <div class="mt-3">
                        <label for="">Ticket Type</label>
                        <input
                          nz-input
                          [(ngModel)]="type_ticket"
                          placeholder="Ticket Type"
                          class="rounded"
                          type="text"
                          readonly
                        />
                      </div>
                      <div class="mt-3">
                        <label for="">Description</label>
                        <textarea
                          nz-input
                          [(ngModel)]="description"
                          placeholder="Description"
                          [nzAutosize]="{ minRows: 5, maxRows: 5 }"
                          readonly
                          class="rounded"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-between">
                        <div class="col-6 d-flex flex-column justify-content-start">
                          <label for="">Order Status</label>
                          <span [ngClass]="classOrder(order_status)">{{statusOrderValue(order_status)}}</span>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                          <button class="btn btn-primary text-white" style="height: 40px" (click)="detailOrder(detailTicket.order_id)">
                            Detail Order
                          </button>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label for="">Receipt Number</label>
                        <nz-input-group class="rounded" [nzSuffix]="resi">
                        <input
                          nz-input
                          [(ngModel)]="awb"
                          placeholder="Receipt Number"
                          class="rounded"
                          type="text"
                          readonly
                        />
                      </nz-input-group>
                      <ng-template #resi>
                        <button class="btn p-0" (click)="salinAWB()">
                          <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg" alt="">
                        </button>
                      </ng-template>
                      </div>
                      <div class="mt-3">
                        <label for="">Seller</label>
                        <input
                          nz-input
                          placeholder="Seller"
                          class="rounded"
                          type="text"
                          readonly
                          [(ngModel)]="seller"
                        />
                      </div>
                      <div class="mt-3">
                        <label for="">Customer</label>
                        <div class="d-flex gap-2">
                          <input
                            nz-input
                            placeholder="Customer"
                            class="rounded"
                            style="width: 87%"
                            type="text"
                            readonly
                            [(ngModel)]="customer"
                          />
                          <button
                            class="btn rounded border d-flex align-items-center"
                            style="height: 32px"
                            (click)="whatsApp(detailTicket.receiver_phone)"
                            [ngStyle]="{'cursor': detailTicket.receiver_phone === '' || detailTicket.receiver_phone === undefined ? 'not-allowed' : ''}"
                          >
                            <img
                              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/Whatsapp.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                      <div class="mt-5" *ngIf="attachments !== null">
                        <label for="">File</label><br />
                        <div class="d-flex flex-column col-8">
                          <a *ngFor="let file of attachments; let index = index" [href]="'https://storage.googleapis.com/komerce-openapi-bucket' + file" target="_blank" class="text-truncate">{{modifyAttachmentURL(file)}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-outline-primary" (click)="showConfirm()" [disabled]="status_ticket === 'Selesai' || status_ticket === 'Dibatalkan'">Cancel Ticket</button>
                    </div>
                  </div>
              </div>
      </div>
    </nz-skeleton>
    <div class="mt-4">
      <nz-skeleton [ngClass]="loading ? 'p-3':'p-0'" [nzLoading]="loading" [nzActive]="true">
        <h6>Ticket History</h6>
        <app-message-ticket [awb]="detailTicket.awb" [shipping]="detailTicket.shipping" [shipping_logo]="detailTicket.shipping_logo" [total_transaction]="detailTicket.transaction_value || 0"></app-message-ticket>
      </nz-skeleton>
    </div>
  </nz-card>
</div>