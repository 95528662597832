import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CANCEL_INVOICE } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-modal-status-payment',
  templateUrl: './modal-status-payment.component.html',
  styleUrl: './modal-status-payment.component.scss'
})
export class ModalStatusPaymentComponent {
  destroy$ = new Subject();

  constructor(
    private route: Router,
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private BaseService: BaseService
  ) {}

  handleCancel() {
    this.activeModal.dismiss()
  }

  handleCheckStatus() {
    this.route.navigate(['/finance/balance/detail'])
    this.activeModal.dismiss()
  }
}
