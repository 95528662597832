<div
  class="otp-container {{ setting.wrapperClass }}"
  id="c_{{ componentKey }}"
  *ngIf="otpForm?.controls"
  [ngStyle]="setting.wrapperStyles"
>
  <input
    type="text"
    numberOnly
    [disabledNumberOnly]="!setting.numbersOnly"
    [ngStyle]="setting.inputStyles"
    maxlength="1"
    class="otp-input {{ setting.inputClass }}"
    autocomplete="off"
    *ngFor="let item of otpForm?.controls | otp; let i = index"
    [formControl]="$any(otpForm.controls[item])"
    id="otp_{{ i }}_{{ componentKey }}"
    (keyup)="keyUp($event, i)"
    (paste)="onPaste($event)"
  />
</div>
