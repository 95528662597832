import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as devEnv } from 'src/environments/environment';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as prodEnv } from 'src/environments/environment.prod';

const environmentConfigs = { development: devEnv, sandbox: sandEnv, production: prodEnv }
@Injectable({
  providedIn: 'root'
})
export class ModeSwitchService {
  // Create a BehaviorSubject to hold the current mode (starts in development mode)
  private modeSubject = new BehaviorSubject(environmentConfigs.development);

  // Expose the BehaviorSubject as an observable
  mode$: Observable<{ mode: string; BASE_URL: string, RECAPTCHA_TOKEN: string, fcm_token: string, durian_pay: Object }> = this.modeSubject.asObservable();

  // Method to switch between modes
  switchMode(newMode: 'development' | 'sandbox') {
    if (newMode in environmentConfigs) {
      this.modeSubject.next(environmentConfigs[newMode]);
    }
  }

  // Optional: get the current mode value as a snapshot (synchronous access)
  getCurrentMode() {
    return this.modeSubject.getValue().BASE_URL;
  }
}
