<div class="login-container">
  <img
    src="https://storage.googleapis.com/komerce/assets/illustration/bg-yellow-ro.png"
    class="background"
  />
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div class="d-flex flex-column gap-3 col-lg-6 col-xl-5 col-12 col-md-6">
        <div class="logo-login">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div>
          <form [formGroup]="formGroup">
            <div
              class="px-md-5 px-3 py-3 m-3 card-glass d-flex flex-column gap-3"
            >
              <div class="text-center desc">
                <strong class="title" style="font-weight: 600"
                  >Register</strong
                >
                <div>Fill out the form below to register</div>
              </div>
              <div class="form-group mt-3">
                <label for="username">Email</label>
                <input
                  nz-input
                  nzSize="large"
                  type="email"
                  (keypress)="setRegex($event)"
                  formControlName="email"
                  class="form-control"
                  id="username"
                  aria-describedby="username"
                  placeholder="username@domain.com"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="validateEmail()"
                  [nzStatus]="validEmail ? '' : 'error'"
                />
                <span class="text-danger">
                  <img
                    *ngIf="errMessEmail !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessEmail }}</span
                >
              </div>
              <div class="form-group mt-3">
                <label for="fullname">Full name/Company</label>
                <input
                  nz-input
                  nzSize="large"
                  type="text"
                  (keypress)="setRegexName($event)"
                  formControlName="fullname"
                  class="form-control"
                  id="fullname"
                  aria-describedby="fullname"
                  minlength="3"
                  placeholder="Enter your full name/company"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="validateFullname()"
                  [nzStatus]="validFullName ? '' : 'error'"
                />
                <span class="text-danger">
                  <img
                    *ngIf="errMessFullName !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessFullName }}</span
                >
              </div>
              <div class="form-group mt-3">
                <label for="name">Telephone Number</label>
                <nz-input-group nzAddOnBefore="+62" nzSize="large">
                  <input
                    type="text"
                    nz-input
                    formControlName="telephone"
                    nzSize="large"
                    placeholder="81234567890"
                    aria-label="Telephone Number"
                    maxlength="12"
                    style="border-radius: 0 8px 8px 0 !important"
                    (keydown)="preventNonNumeric($event)"
                    (input)="validateInput($event)"
                    [nzStatus]="validNumber ? '' : 'error'"
                  />
                </nz-input-group>
                <span class="text-danger">
                  <img
                    *ngIf="errMessNumber !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessNumber }}</span
                >
              </div>
              <div class="form-group mt-3">
                <label for="address">Address</label>
                <input
                  nz-input
                  nzSize="large"
                  type="text"
                  maxlength="100"
                  formControlName="address"
                  class="form-control"
                  id="address"
                  aria-describedby="address"
                  placeholder="Enter your complete address"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="validateAddress()"
                  [nzStatus]="validAddress ? '' : 'error'"
                />
                <div class="d-flex justify-content-between">
                  <span class="text-danger">
                    <img
                      *ngIf="errMessAddress !== ''"
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    {{ errMessAddress }}</span
                  >
                  <span
                    class="d-flex align-items-end justify-content-end"
                    [ngClass]="validAddress ? '' : 'text-danger'"
                    >{{ addressLength }}/100</span
                  >
                </div>
              </div>
              <div class="form-group mt-2">
                <label for="password">Password</label>
                <nz-input-group
                  nzSize="large"
                  [nzSuffix]="suffixTemplate"
                  [nzStatus]="isValidPass ? '' : 'error'"
                >
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="password"
                    minlength="6"
                    maxlength="32"
                    [type]="isShowPassword ? 'text' : 'password'"
                    placeholder="Enter Password"
                    (keydown.enter)="$event.preventDefault()"
                    (blur)="(visibleValPass === false)"
                    (input)="validatePassword()"
                    (keyup)="setRegexPassword($event)"
                    nz-popover
                    [nzPopoverContent]="contentPass"
                    nzPopoverPlacement="top"
                    [(nzPopoverVisible)]="visibleValPass"
                  />
                </nz-input-group>
                <span class="text-danger">
                  <img
                    *ngIf="errMessPass !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessPass }}</span
                >
                <ng-template #contentPass>
                  <div *ngFor="let data of validationPassword">
                    <div
                      *ngIf="data.text"
                      :key="key"
                      class="d-flex align-items-start"
                    >
                      <img
                        *ngIf="data.status"
                        src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-on.svg"
                        alt="tick-success"
                      />
                      <img
                        *ngIf="!data.status"
                        src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-off.svg"
                        alt="tick-success"
                      />
                      <div class="ms-1" style="font-size: 14px">
                        {{ data.text }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #suffixTemplate>
                  <img
                    [src]="isShowPassword ? eyeOff : eyeOn"
                    style="width: 20px !important; height: 20px"
                    (click)="isShowPassword = !isShowPassword"
                  />
                </ng-template>
              </div>
              <div class="form-group mt-3">
                <label for="password">Confirm Password</label>
                <nz-input-group
                  nzSize="large"
                  [nzSuffix]="suffixTemplate2"
                  [nzStatus]="errMessPass2 === '' ? '' : 'error'"
                >
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="confirmPassword"
                    minlength="6"
                    maxlength="32"
                    [type]="isShowPassword2 ? 'text' : 'password'"
                    placeholder="Enter Confirm Password"
                    (keydown.enter)="$event.preventDefault()"
                    (input)="confirmPassword()"
                  />
                </nz-input-group>
                <span class="text-danger">
                  <img
                    *ngIf="errMessPass2 !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessPass2 }}</span
                >
                <ng-template #suffixTemplate2>
                  <img
                    [src]="isShowPassword2 ? eyeOff : eyeOn"
                    style="width: 20px !important; height: 20px"
                    (click)="isShowPassword2 = !isShowPassword2"
                  />
                </ng-template>
              </div>
              <div class="mt-3" (click)="checkEvent()">
                <label
                  nz-checkbox
                  formControlName="checked"
                  (change)="checkEvent()"
                >
                I agree with
                  <span class="text-yellow"
                    >RajaOngkir Terms and Conditions</span
                  >
                </label>
              </div>
              <div class="mt-3">
                <button
                  [disabled]="
                    formGroup.invalid ||
                    checked === false ||
                    errMessPass2 !== '' ||
                    errMessEmail !== '' ||
                    errMessFullName !== '' ||
                    errMessAddress !== '' ||
                    errMessNumber !== ''
                  "
                  (click)="getCaptchaToken($event)"
                  class="btn w-100"
                  [ngClass]="{
                    'btn-secondary':
                      formGroup.invalid ||
                      checked === false ||
                      errMessPass2 !== '' ||
                      errMessEmail !== '' ||
                      errMessFullName !== '' ||
                      errMessAddress !== '' ||
                      errMessNumber !== '',
                    'btn-ro-primary': !(
                      formGroup.invalid ||
                      checked === false ||
                      errMessPass2 !== '' ||
                      errMessEmail !== '' ||
                      errMessFullName !== '' ||
                      errMessAddress !== '' ||
                      errMessNumber !== ''
                    )
                  }"
                >
                  <div
                    class="d-flex flex-row gap-2 align-items-center justify-content-center"
                  >
                    <div style="color: white">Register</div>
                    <div
                      *ngIf="isLoading"
                      class="spinner-border spinner text-light"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
              <div class="mb-5 mt-2 text-center">
                <span>
                  Already have an account?
                  <a href="/login" class="text-yellow"> Login</a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
