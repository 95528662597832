<div class="d-flex justify-content-center flex-column text-center p-5">
  <h5><strong>Payment Methods</strong></h5>
  <span>Please choose the payment method you want.</span>
  <nz-radio-group
    [(ngModel)]="selectedPayment"
    nzSize="large"
    class="d-flex justify-content-center gap-3 mt-3"
    style="height: 290px !important"
  >
    <label
      nz-radio-button
      nzValue="bank_transfer"
      class="col-6 p-0"
      [ngClass]="
        selectedPayment === 'bank_transfer' ? 'selected' : 'nonselected'
      "
      (click)="payNow('bank_transfer')"
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center p-4"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/Bank-metode-lain.svg"
          alt="bank_transfer"
          width="100px"
        />
        <span class="title">Use Bank Transfer</span>
      </div>
      <hr />
      <div
        class="px-4 mb-4"
        style="line-height: 16px !important; font-size: 15px"
      >
        <span class="text-grey"
          >There is an administrative fee of
          <span style="color: #223334"> Rp 1,000.</span></span
        >
      </div>
      <div
        [ngClass]="
          selectedPayment === 'bank_transfer' ? 'bg-footer ' : 'bg-footer-off'
        "
      ></div>
    </label>
    <label
      nz-radio-button
      nzValue="qr_code"
      class="col-6 p-0"
      [ngClass]="selectedPayment === 'qr_code' ? 'selected' : 'nonselected'"
      [nzDisabled]="isQrCodeDisabled"
      (click)="payNow('qr_code')"
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center p-4"
        style="margin-top: 32px"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/Qris-logo.svg"
          alt="qris"
          width="180px"
        />
        <span class="title">Use QRIS</span>
      </div>
      <hr />
      <div class="px-4" style="line-height: 16px !important; font-size: 15px">
        <span class="text-grey">{{ qrDesc }}</span>
        <br />
        <br />
      </div>
      <div
        [ngClass]="{
          'bg-footer': selectedPayment === 'qr_code',
          'bg-footer-off': selectedPayment !== 'qr_code',
          'mt-2': qrDesc !== 'No admin fee.',
          'mt-4': qrDesc === 'No admin fee.',
        }"
      ></div>
    </label>
  </nz-radio-group>
</div>
