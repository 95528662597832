<div class="login-container">
  <img
    src="https://storage.googleapis.com/komerce/assets/illustration/bg-yellow-ro.png"
    class="background"
  />
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div class="d-flex flex-column gap-3">
        <div class="logo-login">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div class="content-form">
          <form [formGroup]="formGroup" *ngIf="!success">
            <div
              class="px-md-5 px-3 py-3 m-3 card shadow d-flex flex-column gap-3"
            >
              <div class="text-center desc">
                <div class="title mt-3 mb-1">
                  <strong>Reset Password</strong>
                </div>
                <div>Enter your new password</div>
              </div>
              <div class="form-group mt-2">
                <label for="password">Password</label>
                <nz-input-group
                  nzSize="large"
                  [nzSuffix]="suffixTemplate"
                  [nzStatus]="isValidPass ? '' : 'error'"
                >
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="password"
                    minlength="6"
                    maxlength="32"
                    [type]="isShowPassword ? 'text' : 'password'"
                    placeholder="Enter Password"
                    (keydown.enter)="$event.preventDefault()"
                    (blur)="(visibleValPass === false)"
                    (input)="validatePassword()"
                    (keyup)="setRegexPassword($event)"
                    nz-popover
                    [nzPopoverContent]="contentPass"
                    nzPopoverPlacement="top"
                    [(nzPopoverVisible)]="visibleValPass"
                  />
                </nz-input-group>
                <small class="text-danger">
                  <img
                    *ngIf="errMessPass !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessPass }}</small
                >
                <ng-template #contentPass>
                  <div *ngFor="let data of validationPassword">
                    <div
                      *ngIf="data.text"
                      :key="key"
                      class="d-flex align-items-start"
                    >
                      <img
                        *ngIf="data.status"
                        src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-on.svg"
                        alt="tick-success"
                      />
                      <img
                        *ngIf="!data.status"
                        src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-off.svg"
                        alt="tick-success"
                      />
                      <div class="ms-1" style="font-size: 14px">
                        {{ data.text }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #suffixTemplate>
                  <img
                    [src]="isShowPassword ? eyeOff : eyeOn"
                    style="width: 20px !important; height: 20px"
                    (click)="isShowPassword = !isShowPassword"
                  />
                </ng-template>
              </div>
              <div class="form-group mt-3">
                <label for="password">Confirm Password</label>
                <nz-input-group
                  nzSize="large"
                  [nzSuffix]="suffixTemplate2"
                  [nzStatus]="errMessPass2 === '' ? '' : 'error'"
                >
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="confirmPassword"
                    minlength="6"
                    maxlength="32"
                    [type]="isShowPassword2 ? 'text' : 'password'"
                    placeholder="Enter Password Confirmation"
                    (keydown.enter)="$event.preventDefault()"
                    (input)="confirmPassword()"
                  />
                </nz-input-group>
                <small class="text-danger">
                  <img
                    *ngIf="errMessPass2 !== ''"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessPass2 }}</small
                >
                <ng-template #suffixTemplate2>
                  <img
                    [src]="isShowPassword2 ? eyeOff : eyeOn"
                    style="width: 20px !important; height: 20px"
                    (click)="isShowPassword2 = !isShowPassword2"
                  />
                </ng-template>
              </div>
              <div class="mb-2 mt-3">
                <button
                  [disabled]="formGroup.invalid || errMessPass2 !== ''"
                  (click)="getCaptchaToken($event)"
                  class="btn w-100"
                  [ngClass]="
                    formGroup.invalid || errMessPass2 !== ''
                      ? 'btn-secondary'
                      : 'btn-ro-primary'
                  "
                >
                  <div
                    class="d-flex flex-row gap-2 align-items-center justify-content-center"
                  >
                    <div style="color: white">Submit</div>
                    <div
                      *ngIf="isLoading"
                      class="spinner-border spinner text-light"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
              <div class="text-center mb-2">
                Do you remember your password?
                <a class="text-yellow" href="/login">Login</a>
              </div>
            </div>
          </form>
          <div
            *ngIf="success"
            class="card shadow p-5 d-flex align-items-center justify-content-center gap-3"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/key-square-success.svg"
              alt="succes"
              style="max-width: 50px"
            />
            <h5><strong>Password Successfully Changed</strong></h5>
            <span>Please log in with your new password</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
