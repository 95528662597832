import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'otp'
})
export class OtpPipe implements PipeTransform {
  transform(value: any): string[] {
    return Object.keys(value) || true
  }
}
