<div class="d-flex justify-content-center flex-column text-center">
  <div class="mb-3">
    <img
      src="https://storage.googleapis.com/komerce/assets/icons/mail-yellow-ro.svg"
      alt="mail"
    />
  </div>
  <h5><strong>Check Your Email</strong></h5>
  <span>Please verify via the email we have sent</span>
  <button
    style="border-radius: 8px"
    class="mt-3"
    nz-button
    nzType="primary"
    (click)="destroyModal()"
  >
    Oke
  </button>
</div>
