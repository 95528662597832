<div class="row">
  <div class="mt-2 col-8">
    <div class="border rounded message-content p-4" style="height: 400px; overflow-y: scroll;" #messageContainer [scrollTop]="messageContainer.scrollHeight">
      <ng-container *ngFor="let message of chatContent; let index = index">
        <div class="d-flex gap-2" *ngIf="message.type !== 'Partner'">
          <nz-avatar
          [nzText]="message.sender_name"
          ></nz-avatar>
          <div class="d-flex flex-column col-11" style="width: auto;">
            <div class="d-flex justify-content-between">
              <small class="d-flex justify-content-start">
                {{message.sender_name}}</small>
              <small class="d-flex justify-content-end"
                >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt=""
                  width="15px"
                  class="ms-1 d-none"
                  placement="top right"
                  ngbPopover="You can claim compensation for returns (shipping costs) if the expedition returns unilaterally even though the customer still wants to pay."
                  triggers="mouseenter:mouseleave"
                />
              </small>
            </div>
            <div class="chat-receive d-flex flex-column p-2 text-left" style="white-space: pre-line;">
              <img
              *ngFor="let file of message.attachment; let index = index"
              (click)="showModal(file)"
              [src]="'https://storage.googleapis.com/komerce-openapi-bucket' + file"
              alt=""
              style="max-width: 200px; max-height: 200px"
              class="rounded mt-2"
            />
              {{ message.message }}
            </div>
            <a *ngFor="let file of message.attachment; let index = index" [href]="'https://storage.googleapis.com/komerce-openapi-bucket' + file" target="_blank" [ngClass]="isImageFile(file) ? 'd-none' : ''" class="text-truncate">{{modifyAttachmentURL(file)}}</a>
            <small
              >{{ message.created_at | date : "dd MMMM yyyy" }} |
              {{ message.created_at.slice(-5) }} WIB</small
            >
          </div>
        </div>
        <div
          class="d-flex gap-2 mt-2 justify-content-end"
          *ngIf="message.type === 'Partner'"
        >
          <div class="d-flex flex-column" style="width: auto;">
            <small class="d-flex justify-content-end">
              {{message.sender_name}}</small>
            <div class="chat-sent p-2 text-white d-flex flex-column text-left" style="white-space: pre-line;" *ngIf="message.message !== ''">
              <img
              *ngFor="let file of message.attachment; let index = index"
              (click)="showModal(file)"
              [src]="'https://storage.googleapis.com/komerce-openapi-bucket' + file"
              alt=""
              style="max-width: 200px; max-height: 200px"
              class="rounded mt-2"
            />
              {{ message.message }}
            </div>
            <a *ngFor="let file of message.attachment; let index = index" [href]="'https://storage.googleapis.com/komerce-openapi-bucket' + file" target="_blank" [ngClass]="isImageFile(file) ? 'd-none' : ''" class="text-truncate">{{modifyAttachmentURL(file)}}</a>
            <small class="d-flex justify-content-end"
              >{{ message.created_at | date : "dd MMMM yyyy" }} |
              {{ message.created_at.slice(-5) }} WIB</small
            >
          </div>
          <nz-avatar [nzText]="message.sender_name"></nz-avatar>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mt-2 col-4">
    <div class="border rounded history-content p-4">
      <span
        >Transaction Value
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
          alt=""
          width="15px"
          class="ms-1"
          placement="top right"
          ngbPopover="The total amount the buyer must pay"
          triggers="mouseenter:mouseleave"
      /></span>
      <h5 class="text-green mt-2">{{total_transaction | idr}}</h5>
      <hr />
      <span class="d-flex gap-2">
        <b>AWB History</b>
        <img [src]="shipping_logo" alt="shipping_logo" style="max-width: 40px; max-height: 40px;">
      </span>
      <hr />
      <div class="d-flex flex-column" style="height: 230px; overflow-y: scroll !important">
        <div *ngFor="let awb of awbHistory; let i = index" class="ms-2">
          <div class="d-flex">
            <div class="d-flex flex-column align-items-center">
              <img [src]="iconAwb(awbHistory[i].status)" alt="" />
              <img
                *ngIf="i !== awbHistory.length - 1"
                src="https://storage.googleapis.com/komerce/assets/illustration/Line-Ticketing.svg"
                alt=""
                height="70px"
              />
            </div>
            <div class="ms-3">
              <span
                >{{ awbHistory[i].date | date : "dd MMMM yyyy" }} -
                {{ awbHistory[i].date.slice(10) }}</span
              >
              <br />
              <strong style="color: rgb(84, 84, 84)">{{
                awbHistory[i].desc | uppercase
              }}</strong>
            </div>
          </div>
        </div>
        <div *ngIf="awbHistory.length === 0">
          <p>
            AWB history data not found. <b>It could be</b> that you have requested pickup/been picked up by the courier during pickup but the QR code to start the journey at the branch office has not been scanned. Please wait.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-4">
  <div
    *ngFor="let file of fileUpload; let i = index"
    class="image-container gap-2"
  >
    <button class="btn delete-btn d-flex align-items-center" (click)="deleteFile(i)">
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/trash.svg"
        alt="remove"
        width="20px"
      />
    </button>
    <div class="d-flex flex-column">
      <img
        [src]="fileName[i].url"
        alt="{{ fileName[i].name }}"
        style="max-width: 100px; max-height: 100px"
        class="mx-1"
      />
      <small style="width: 100px" class="text-truncate">{{ file.name }}</small>
    </div>
  </div>
  <input
    type="file"
    name="file"
    class="form-control d-none"
    id="attachment"
    (change)="handleFileUpload($event)"
    #fileupload
    multiple
  />
  <nz-input-group [nzSuffix]="actionbutton" class="rounded">
    <textarea required nz-input placeholder="Write a message to respond" nzAutosize nzBorderless [(ngModel)]="messages" (ngModelChange)="disableSend($event)"></textarea>
  </nz-input-group>
  <ng-template #actionbutton>
    <button class="btn p-0" (click)="triggerFileInput()">
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/link.svg"
        alt=""
      />
    </button>
    <div class="line"></div>
    <button [disabled]="isDisable" class="btn p-0" style="border: none;" (click)="sendMessages()">
      <img
      *ngIf="isDisable"
        src="https://storage.googleapis.com/komerce/assets/icons/send-chat.svg"
        alt=""
      />
      <img
      *ngIf="!isDisable"
        src="https://storage.googleapis.com/komerce/assets/icons/send-chat-active.svg"
        alt=""
      />
    </button>
  </ng-template>
</div>
<nz-modal
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalContent>
    <div class="d-flex align-items-center justify-content-center">
      <img
      [src]="
        'https://storage.googleapis.com/komerce-openapi-bucket' + indexImage
      "
      alt="img-chat"
      style="max-width: 400px; max-height: 400px"
    /> 
    </div>
    </ng-template
></nz-modal>
