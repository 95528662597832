import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  EMPTY,
  Subject,
  Subscription,
  catchError,
  interval,
  takeUntil,
} from 'rxjs';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import { ModalMethodPaymentComponent } from '../modal-method-payment/modal-method-payment.component';

@Component({
  selector: 'modal-top-up-balance',
  templateUrl: './modal-top-up-balance.component.html',
  styleUrls: ['./modal-top-up-balance.component.scss'],
})
export class ModalTopUpSaldoComponent implements OnInit {
  @Output() filterResult = new EventEmitter();
  destroy$ = new Subject();
  isDisabled: boolean = true;
  formGroup!: FormGroup;
  nominal!: number;
  textAlertMin: string = '';
  balance: BalanceResponseType = {
    balance: 0,
    commission: 0,
  };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {}

  formatNumber(n: any) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  handleNominal(value: any) {
    if (value === '') {
      this.isDisabled = true;
      this.textAlertMin = '';
    } else {
      this.isDisabled = false;
      this.textAlertMin = '';
    }

    const input = <HTMLInputElement>document.getElementById('nominal-input');
    let inputVal = (<HTMLInputElement>document.getElementById('nominal-input'))
      .value;

    if (inputVal === '' || inputVal === 'Rp' || inputVal === 'Rp ') {
      return;
    }

    const originalLen = inputVal.length;
    let caretPos = input.selectionStart;

    if (inputVal.indexOf(',') >= 0) {
      const decimalPos = inputVal.indexOf(',');
      let leftSide = inputVal.substring(0, decimalPos);
      let rightSide = inputVal.substring(decimalPos);

      leftSide = this.formatNumber(leftSide);
      rightSide = this.formatNumber(rightSide);

      rightSide = rightSide.substring(0, 2);
      inputVal = leftSide + ',' + rightSide; // eslint-disable-line
    } else {
      inputVal = inputVal.replace(/\./g, '');
      inputVal = this.formatNumber(inputVal);
    }

    input.value = inputVal;

    const updatedLen = inputVal.length;
    caretPos = updatedLen - originalLen + caretPos!;
    input.setSelectionRange(caretPos, caretPos);

    const valEnd = inputVal.replace(/\./g, '');

    if (parseInt(valEnd, 10) > 999999999999) {
      this.isDisabled = true;
      this.textAlertMin =
        'Amount exceeds the maximum limit. Please try a lower amount';
    } else if (parseInt(valEnd, 10) < 10000) {
      this.isDisabled = true;
      this.textAlertMin = 'Minimum topup balance Rp10.000';
    } else {
      this.isDisabled = false;
      this.textAlertMin = '';
    }
  }

  handleClose(e: any): void {
    this.activeModal.dismiss();
    this.textAlertMin = '';
  }

  handleTopUp() {
    this.activeModal.dismiss();
    const modal = this.modalService.open(ModalMethodPaymentComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
      keyboard: false,
    });

    modal.componentInstance.totalPrice = this.nominal
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
