import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './components/toast/toast.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { CounterDirective } from 'src/app/directives/timer.directive';
import { OtpPipe } from 'src/app/pipes/otp.pipe';
import { NumberOnly } from 'src/app/directives/number-only.directive';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { BlokReqWDComponent } from './components/modal-blocked-request-wd/blocked-req-wd.component';
import { OtpPhoneInputComponent } from './components/otp-phone-input/otp-phone-input.component';
import { ModalTopUpSaldoComponent } from './components/modal-top-up-balance/modal-top-up-balance.component';
import { AntDModule } from './antd.module';
import { ModalMethodPaymentComponent } from './components/modal-method-payment/modal-method-payment.component';
import { ModalQrisPaymentComponent } from './components/modal-qris-payment/modal-qris-payment.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LibsModule } from 'src/app/libs/libs.module';
import { ModalCheckPaymentComponent } from './components/modal-check-payment/modal-check-payment.component';
import { ModalStatusPaymentComponent } from './components/modal-status-payment/modal-status-payment.component';

@NgModule({
  declarations: [
    ToastComponent,
    OtpInputComponent,
    OtpPhoneInputComponent,
    OtpPipe,
    CounterDirective,
    NumberOnly,
    MaintenanceComponent,
    BlokReqWDComponent,
    ModalTopUpSaldoComponent,
    ModalMethodPaymentComponent,
    ModalQrisPaymentComponent,
    ModalCheckPaymentComponent,
    ModalStatusPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AntDModule,
    QRCodeModule,
    LibsModule
  ],
  exports: [
    CommonModule,
    ToastComponent,
    OtpInputComponent,
    OtpPhoneInputComponent,
    CounterDirective,
    OtpPipe,
    NumberOnly,
    ModalTopUpSaldoComponent,
    ModalMethodPaymentComponent,
    ModalQrisPaymentComponent,
    ModalCheckPaymentComponent,
    ModalStatusPaymentComponent
  ],
})
export class SharedModule {}
