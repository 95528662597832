<div class="card p-3 d-flex flex-column gap-3 data-order mt-2 col-12"
  [ngStyle]="{'height': totalOrder < 5 ? '850px' : 'auto'}">
  <nz-skeleton [ngClass]="loading ? 'p-3' : 'p-0'" [nzLoading]="loading" [nzActive]="true">
    <div class="d-flex justify-content-between mb-3">
      <div class="title d-flex justify-content-start">Order Data</div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-primary download d-flex align-items-center"
          (click)="download()">Download</button>
      </div>
    </div>
    <div class="d-flex flex-row gap-3 justify-content-between align-items-center mb-3">
      <div class="total-order w-100">
        Total Orders: <b>{{ totalOrder || 0 }}</b>
      </div>
      <div class="w-100 d-flex justify-content-end flex-row gap-2 align-items-center">
        <div>
          <div class="form-group has-search">
            <span class="form-control-feedback"><i class="icon-search-normal-1"></i></span>
            <input [(ngModel)]="filterOrder.awb" (ngModelChange)="onChangeSearch($event)" type="text"
              class="form-control" placeholder="Receipt Number">
          </div>
        </div>
        <div>
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary btn-filter" id="dropdownfilterorder" ngbDropdownToggle>
              <i class="icon-filter-search text-white"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownfilterorder">
              <button ngbDropdownItem disabled class="text-black">
                <b>Filter</b>
              </button>
              <div ngbDropdown placement="left" class="d-inline-block w-100">
                <button type="button" class="btn w-100" id="dropdownstatus" style="text-align: start;"
                  ngbDropdownToggle>
                  Status
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownstatus">
                  <button class="btn w-100" style="text-align: start;">
                    <div class="form-check" *ngFor="let list of status; let i = index">
                      <input class="form-check-input" type="checkbox" (value)="list.value" (id)="list.id"
                        (change)="getStatus($event, list)" [checked]="list.checked">
                      <label class="form-check-label" [for]="list.id">
                        {{list.label}}
                      </label>
                    </div>
                  </button>
                </div>
                <button type="button" class="btn w-100 ngx-daterangepicker-action" id="dropdownstatus"
                  style="text-align: start;" (click)="openDatepicker()">
                  Period
                </button>
                <div id="dropdownstatus">
                  <input type="text" class="btn w-100" ngxDaterangepickerMd [(ngModel)]="selected"
                    [ngModelOptions]="{standalone: true}" [showCustomRangeLabel]="false"
                    [keepCalendarOpeningWithRange]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                    [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showCancel]="true" opens="center"
                    drops="down" [locale]="setlocale" (change)="onChangeDate($event)"
                    style="border: none; font-size: 14px; display: none;" #datePickerInput />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-table-data-order [dataOrder]="dataOrder" [loadData]="loadData"></app-table-data-order>
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
      <div class="flex-row d-flex align-items-center">
        <div>Items per page:</div>
        <div class="btn-group ms-2" role="group" aria-label="First group">
          <div class="d-flex">
            <div [ngClass]="filterOrder.per_page === '20' || filterOrder.per_page === 20 ? 'btn-primary' : ''"
              class="d-flex gap-3 btn-pg ms-1 radio-container">
              <input type="radio" [name]="'radio'" [id]="'radio'" [value]="20" class="btn btn-prim"
                (change)="getListPage($event)" />
              <label for="">20</label>
            </div>
            <div [ngClass]="filterOrder.per_page === '50' ? 'btn-primary' : ''"
              class="d-flex gap-3 btn-pg ms-1 radio-container">
              <input type="radio" [name]="'radio'" [id]="'radio'" [value]="50" class="btn-pg btn-prim"
                (change)="getListPage($event)" />
              <label for="">50</label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group mt-3 mt-md-0 d-flex align-items-center" role="group" aria-label="First group">
        <button [disabled]="isDisPrev" type="button" class="btn-pg" (click)="prevPage()">
          <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg" alt=""
            width="20px">
        </button>
        <button *ngIf="filterOrder.page > 1" class="btn number-pn d-flex align-items-center justify-content-center"
          (click)="prevPage()">{{filterOrder.page - 1}}</button>
        <button class="btn number-page">
          {{filterOrder.page}}
        </button>
        <button [disabled]="isDisNext" *ngIf="totalOrder/totalPage < filterOrder.per_page"
          class="btn number-pn d-flex align-items-center justify-content-center"
          [ngClass]="filterOrder.page + 1 > totalPage ? 'd-none' : ''" (click)="nextPage()">{{+filterOrder.page +
          1}}</button>
        <button [disabled]="isDisNext" *ngIf="totalPage > 2"
          class="btn number-pn d-flex align-items-center justify-content-center"
          [ngClass]="filterOrder.page !== 1 ? 'd-none' : ''" (click)="nextThree()">3</button>
        <button [disabled]="isDisNext" type="button" class="btn-pg" (click)="nextPage()">
          <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg" alt=""
            width="20px">
        </button>
      </div>
    </div>
  </nz-skeleton>
</div>