import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './api/user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Profile } from '../models/profile.model';
import { environment as prodEnv } from 'src/environments/environment.prod';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as devEnv } from 'src/environments/environment';
import { BypassInterceptorContext } from '../interceptors/http.interceptor';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private destroy$ = new Subject<void>();
  currentMode = 'development';

  private profileDataSubject = new BehaviorSubject<Profile | null>(null);
  profileData$ = this.profileDataSubject.asObservable();

  constructor(
    private storageService: LocalStorageService,
    private userService: UserService,
    private httpClient: HttpClient,
  ) { }

  getProfile(): void {
    const currentMode = localStorage.getItem('mode') || 'development'
    this.currentMode = currentMode

    const baseUrl =
      this.currentMode === 'development'
        ? devEnv.BASE_URL
        : sandEnv.BASE_URL;
    const endPoint = '/user/api/v1/user/profile';
    const apiUrl = baseUrl + endPoint;

    const token = localStorage.getItem('auth-token');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);
  
    this.httpClient.get<any>(apiUrl, { headers, context }).subscribe({
      next: (res) => {
        if (res.data.is_rajaongkir === true && res.data.is_openapi === true) {
          localStorage.setItem('role-user', 'all-role');
        } else if (res.data.is_rajaongkir === true) {
          localStorage.setItem('role-user', 'raja-ongkir');
        } else {
          localStorage.setItem('role-user', 'open-api');
        }

        this.profileDataSubject.next(res.data);
      },
      error: (error) => {
      },
    });
  }

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
