<div class="text-center d-flex flex-column p-4">
    <div>
        <img src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png" alt="warning" width="100px">
        <div class="title mt-3">Finish your payment to successfully complete the top-up!</div>
    </div>
    <span class="mt-3 text-grey">
        Your balance will be updated once the payment is complete
    </span>
    <div class="d-flex mt-3 gap-2">
        <button class="btn btn-primary text-white col-12" (click)="handleCheckStatus()">Check Detail Balance</button>
    </div>
</div>