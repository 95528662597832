<div
  class="d-flex flex-column py-4 gap-3 justify-content-center align-items-center"
>
  <div
    class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center mt-4"
  >
    <div class="title text-center">Top Up Balance</div>
    <span class="text-center"
      >Top up your balance quickly and keep your account ready anytime</span
    >

    <div class="mt-3 w-100">
      <label for="exampleInputPassword1" class="form-label">Amount</label>
      <nz-input-group
        nzPrefix="Rp"
        [nzStatus]="textAlertMin !== '' ? 'error' : ''"
      >
        <input
          nz-input
          type="text"
          name="amountNumber"
          numberOnly
          myCurrencyFormatter
          id="nominal-input"
          placeholder="Minimum topup balance Rp10.000"
          style="height: 32px !important"
          [(ngModel)]="nominal"
          (ngModelChange)="handleNominal($event)"
        />
      </nz-input-group>
      <small class="text-danger">{{ textAlertMin }}</small>
    </div>

    <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
      <button
        (click)="handleClose($event)"
        class="btn btn-outline-primary w-50"
      >
        Cancel
      </button>
      <button
        class="btn w-50"
        [disabled]="isDisabled"
        [ngClass]="
          isDisabled ? 'btn-outline-secondary' : 'btn-primary text-white'
        "
        (click)="handleTopUp()"
      >
        <div class="ps-3 pe-3">Top Up</div>
      </button>
    </div>
  </div>
</div>
