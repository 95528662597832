import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PAY_PACKAGE, TOPUP_BALANCE } from 'src/app/constants/endpoint.const';
import { Subject, takeUntil } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalQrisPaymentComponent } from '../modal-qris-payment/modal-qris-payment.component';
import { ModalStatusPaymentComponent } from '../modal-status-payment/modal-status-payment.component';

@Component({
  selector: 'app-modal-method-payment',
  templateUrl: './modal-method-payment.component.html',
  styleUrls: ['./modal-method-payment.component.scss'],
})
export class ModalMethodPaymentComponent implements OnInit {
  destroy$ = new Subject();
  @Input() invoiceId: string = '';
  @Input() totalPrice: string = '';
  @Input() packageId: number = 0;
  @Input() allowQris: boolean = true;
  selectedPayment: string = 'bank_transfer';
  @Input() qrCode: string = '';
  isQrCodeDisabled: boolean = false;
  qrDesc: string = 'No admin fee.';

  constructor(
    private route: Router,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private BaseService: BaseService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.checkQrCodeDisabled();
  }

  checkQrCodeDisabled(): void {
    const amount = Number(this.totalPrice.replace(/\./g, ''));
    const currentDay = new Date().getDay();
    const today = new Date();

    const thursday = new Date(today);
    thursday.setDate(today.getDate() + (4 - today.getDay()));

    const saturday = new Date(today);
    saturday.setDate(today.getDate() + (6 - today.getDay()));

    const monthYearOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      year: 'numeric',
    };

    const dayOptions: Intl.DateTimeFormatOptions = {
      day: '2-digit',
    };

    if (currentDay === 4 || currentDay === 5 || currentDay === 6) {
      this.isQrCodeDisabled = true;

      const formattedDayStart = thursday.toLocaleDateString(
        'en-US',
        dayOptions
      );
      const formattedDayEnd = saturday.toLocaleDateString('en-US', dayOptions);
      const formattedMonthYear = thursday.toLocaleDateString(
        'en-US',
        monthYearOptions
      );

      this.qrDesc = `QRIS is disabled from ${formattedDayStart} - ${formattedDayEnd} ${formattedMonthYear}`;
    } else if (amount > 500000) {
      this.isQrCodeDisabled = true;
    } else {
      this.isQrCodeDisabled = false;
      this.qrDesc = 'No admin fee.';
    }
  }

  destroyModal(): void {
    this.activeModal.close();
  }
  formatNumber(n: any) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  payNow(e: string) {
    const amount = Number(this.totalPrice.replace(/\./g, ''));
    const params = {
      payment_method: e,
      amount: amount,
    };

    if (e === 'qr_code' && this.isQrCodeDisabled) {
      this.message.create('error', "Can't use QRIS");
      return;
    }

    this.BaseService.postData(TOPUP_BALANCE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (e === 'bank_transfer') {
            this.route.navigate(['billings']);
            this.message.create(
              'success',
              `Top up complete! Rp ${this.formatNumber(
                this.totalPrice
              )} has been added to your balance`
            );
            window.open(res.data.invoice_url, '_blank');
            this.activeModal.dismiss();
            const modal = this.modalService.open(ModalStatusPaymentComponent, {
              size: 'md',
              backdrop: 'static',
              keyboard: false,
              centered: true,
            });
          } else {
            const modal = this.modalService.open(ModalQrisPaymentComponent, {
              size: 'md',
              backdrop: 'static',
              keyboard: false,
              centered: true,
            });
            Object.assign(modal.componentInstance, {
              invoiceId: this.invoiceId || res.data.invoice_id,
              allowQris: this.allowQris,
              packageId: this.packageId,
              expiredAt: res.data.expired_at,
              totalPrice: res.data.amount,
              qrCode: this.qrCode || res.data.qr_string,
            });
          }

          this.activeModal.close();
        },
        (err) => {
          this.message.create('error', err.data.errors);
        }
      );
  }

  back() {
    this.route.navigate(['billings/upgrade-package']);
  }
}
