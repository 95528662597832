import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbTypeahead,
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-modal-buat-pin',
  templateUrl: './buat-pin.component.html',
  styleUrls: ['./buat-pin.component.scss'],
})
export class BuatPinComponent implements OnInit {
  contentText: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private route: Router,
    private activeRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    let currentUrl = this.route.url;

    if (currentUrl === '/rekening') {
      this.contentText =
        'Create a PIN for security when withdrawing your balance. Enter your 6-digit PIN and make sure you remember it.';
    } else {
      this.contentText =
        `You haven't created a PIN, add your PIN to continue your balance withdrawal request.`;
    }
  }

  handleClose(e: any): void {
    let currentUrl = this.route.url;

    if (currentUrl === '/rekening') {
      this.route.navigate(['/profile']);
      this.activeModal.dismiss();
    } else {
      this.activeModal.dismiss();
    }
  }

  handleConfirm(e: any): void {
    this.route.navigate(['pin']);
    this.activeModal.dismiss();
  }
}
