import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CANCEL_INVOICE } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';
import { ModalQrisPaymentComponent } from '../modal-qris-payment/modal-qris-payment.component';

@Component({
  selector: 'app-modal-check-payment',
  templateUrl: './modal-check-payment.component.html',
  styleUrl: './modal-check-payment.component.scss'
})
export class ModalCheckPaymentComponent {
  destroy$ = new Subject();
  @Input() invoiceID: string = '';
  @Input() urlPayment: string = '';
  @Input() totalPrice: number = 0;
  @Input() packageId: number = 0;
  @Input() allowQris: boolean = true;
  @Input() isQris: boolean = true;
  @Input() expiredAt: string = '';
  @Input() qrCode: string = '';

  constructor(
    private route: Router,
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private BaseService: BaseService,
    private modalService: NgbModal,
  ) {}

  handleCancel() {
    const params = {
      invoice_id: this.invoiceID,
    };
    this.BaseService.putData(CANCEL_INVOICE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.activeModal.close();
          this.message.create('success', 'Your payment has been canceled');
        },
        (err) => {
          this.message.create('error', err.data.errors);
        }
      );
  }

  handleCheckStatus() {
    this.activeModal.dismiss()
    if (this.isQris === true) {
      const modal = this.modalService.open(ModalQrisPaymentComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });
      modal.componentInstance.invoiceId = this.invoiceID;
      modal.componentInstance.allowQris = this.allowQris;
      modal.componentInstance.totalPrice = this.totalPrice;
      modal.componentInstance.packageId = this.packageId;
      modal.componentInstance.expiredAt = this.expiredAt;
      modal.componentInstance.qrCode = this.qrCode;
    } else {
      window.open(this.urlPayment, '_blank');
      this.route.navigate(['billings']);
      this.activeModal.close();
    }
  }
}
