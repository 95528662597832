import {
  Component,
  Input,
  LOCALE_ID,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BalanceService } from 'src/app/services/api/balance.service';
import { detailBalanceModel } from 'src/app/models/detail-balance.model';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-detail-riwayat',
  templateUrl: './detail-riwayat-penarikan-saldo.component.html',
  styleUrls: ['./detail-riwayat-penarikan-saldo.component.scss'],
})
export class DetailRiwayatSaldoComponent {
  detailBalance!: detailBalanceModel;
  destroy$ = new Subject();
  wdID!: number;
  Rekening!: string;

  @Input() paramsDetail = {
    withdrawal_id: this.wdID,
  };

  constructor(
    private balanceService: BalanceService,
    private activeRoute: ActivatedRoute,
    private route: Router,
    private toastService: ToastService
  ) {}
  ngOnInit(): void {
    this.getDetailBalance();
  }

  back() {
    this.route.navigate(['finance/balance']);
  }

  getDetailBalance() {
    this.activeRoute.params.subscribe((params) => {
      this.wdID = Number(params['id']);
    });

    this.balanceService
      .detailWithdraw(this.wdID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.detailBalance = res.data;
        this.Rekening = res.data.bank_number;
      });
  }

  salinCode(): void {
    const norek = this.Rekening;
    navigator.clipboard
      .writeText(norek)
      .then(() => {
        this.toastService.show(
          null,
          'Account has been successfully copied',
          'success',
          {
            classname: 'bg-success',
            delay: 3000,
          }
        );
      })
      .catch((e) => console.log(e));
  }
}
